.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-image: url(https://static.vecteezy.com/system/resources/previews/005/298/806/non_2x/seamless-pattern-of-cute-cartoon-cat-illustration-design-on-purple-background-free-vector.jpg);
}

form {
  margin-bottom: 2rem;
}

.container {
  margin-top: 1rem;
  margin-left: 25%;
  margin-right: 25%;
  background-color: rgba(255, 255, 255, 0.7);
}

.error-404 {
  background-color: rgba(255, 255, 255, 0.8);
}

.cat-image-container {
  max-width: 100%;
}

.cat-image {
  max-width: fit-content;
  max-height: 60vh;
}

.footer-container {
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}

.footer-children {
  margin-bottom: 1rem;
}
